import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import MealCard from "../../../PublicSector/MealCard";
import { submitNotification } from "./../../../../../lib/Notifications";
import { Button } from "react-bootstrap";
import { createCouncilMenu } from "../../../../../../store/actions/marketplaceActions/restaurantData";
// import  from "../../../../../../SubComponents/Button";

import { Modal } from "react-bootstrap";
// import AddMenuForm from "./AddMenuForm";
import { AddButton } from "./../../../../../SubComponents/Button";
import { firestoreConnect } from "react-redux-firebase";

const AddCouncilMealModal = (props) => {
  const { show, setShow, profile, governmentUsers } = props;
  const [image, setImage] = useState(null);
  const handleForm = () => setShow(true);
  const [mealList, setMealList] = useState([]);
  const [selectedMeals, setSelectedMeals] = useState(new Set());
  const [createMenuLoading, setCreateMenuLoading] = useState(false);
  const handleFormClose = () => setShow(false);

  console.log({ selectedMeals });

  //saves recipe to saved meal list
  const [save, setSave] = useState(true);

  const handleSave = () => {
    setSave(!save);
  };

  useEffect(() => {
    setMealList([]);
    if (
      governmentUsers &&
      profile.uid &&
      governmentUsers["kXad8bWGmjbBf155c7cAJG8Ykg83"] &&
      governmentUsers["kXad8bWGmjbBf155c7cAJG8Ykg83"].meals
    ) {
      extractMeals(governmentUsers["kXad8bWGmjbBf155c7cAJG8Ykg83"].meals);
      // extractSuppliers(governmentUsers[profile.uid].suppliers);
    }
  }, [governmentUsers, profile.uid]);

  const extractMeals = (data) => {
    const mealSet = new Set();
    data.forEach((meal) => mealSet.add(JSON.stringify(meal)));
    const mealArray = Array.from(mealSet).map((meal) => JSON.parse(meal));
    setMealList(mealArray);
  };

  const handleSelectMeal = (meal) => {
    const newSelectedMeals = new Set(selectedMeals);
    if (newSelectedMeals.has(meal)) {
      newSelectedMeals.delete(meal);
    } else {
      newSelectedMeals.add(meal);
    }
    setSelectedMeals(newSelectedMeals);
  };

  //fired when click "done"
  const handleSubmit = () => {
    const mealsArray = Array.from(selectedMeals).map((meal) => ({
      name: meal.name,
      description: meal.description,
      nutritionalComposition: {
        portionSize: meal.portionSize || "",
        energyKCal: meal.energyKCal || "",
        fat: meal.fat || "",
        satFat: meal.satFat || "",
        carb: meal.carb || "",
        fibre: meal.fibre || "",
        sodium: meal.sodium || "",
        sugar: meal.sugar || "",
        protein: meal.protein || "",
        calcium: meal.calcium || "",
      },
      mealRecipe: {
        recipe: meal.recipe || [""],
        method: meal.method || [""],
        allergy: meal.allergy || [""],
      },
      dietType: meal.dietType || "None",
      suppliers: meal.suppliers || [""],
    }));

    const data = {
      upload: {
        meals: mealsArray,
        city: profile.city,
        region: profile.region,
        country: profile.country,
        mobile: profile.mobile,
        email: profile.email,
      },
    };

    setCreateMenuLoading(true);

    props
      .createCouncilMenu(data)
      .then(() => {
        submitNotification("Success", "Dish has been added to menu!");
        setCreateMenuLoading(false);
        handleFormClose();
      })
      .catch((err) => {
        console.log(err);
        submitNotification("Error", "Something went wrong");
        setCreateMenuLoading(false);
      });
  };

  return (
    <div>
      <Tooltip title="add" arrow>
        <div className="button">
          <AddButton onClick={handleForm} />
        </div>
      </Tooltip>
      <Modal
        show={show}
        onHide={handleFormClose}
        size="lg"
        aria-labelledby="add item"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="add-item" className="basic-title-left basic-lg">
            Add New Council Meal
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <AddMenuForm
						handleFormClose={handleFormClose}
						// update={update}
						// setUpdate={setUpdate}
					/> */}
          {mealList.length === 0 ? (
            <Typography
              variant="h6"
              align="center"
              color="textSecondary"
              style={{ marginTop: 20 }}
            >
              No Meal
            </Typography>
          ) : (
            <Box sx={{ flexGrow: 1, margin: "10px" }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                {mealList.map((meal, index) => (
                  <Grid item xs={2} sm={4} md={4} key={index}>
                    <MealCard
                      meal={meal}
                      handleEdit={null}
                      handleDeleteMeal={null}
                      handleSelectMeal={handleSelectMeal}
                      isSelected={selectedMeals.has(meal)}
                    />
                  </Grid>
                ))}
              </Grid>

              <div style={{ alignItems: "center", margin: "20px" }}>
                <Button
                  className="blue-btn shadow-none"
                  type="submit"
                  onClick={() => {
                    handleSave();
                    handleSubmit();
                  }}
                >
                  {createMenuLoading ? "...loading" : "Submit"}
                </Button>
              </div>
            </Box>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    governmentUsers: state.firestore.data.government_users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createCouncilMenu: (data) => dispatch(createCouncilMenu(data)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    if (!props.auth || props.auth.isEmpty) return [];
    return [
      {
        collection: "government_users",
        storeAs: "government_users",
      },
    ];
  })
)(AddCouncilMealModal);

// const data = {
//   upload: {
//     meal: mealName,
//     mealDescription: mealDescription,
//     mealPrice: parseInt(mealPrice),
//     costPrice: parseInt(costPrice),
//     mealCurrency: mealCurrency,
//     menuSection: menuSection,
//     metric: metricGroups,
//     metricUnit: metricUnit,
//     // mealType: mealType,
//     ingredients: inputGroups,
//     restaurantName: props.profile.restaurantName,
//     city: props.profile.city,
//     region: props.profile.region,
//     country: props.profile.country,
//     mobile: props.profile.mobile,
//     email: props.profile.email,
//   },
// };
