import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { fs, FieldValue } from "../../../../config/fbConfig";
import Food  from "../../../../images/Food.jpg";
import InputField from "./InputField";
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card'; 
import  { Grid }  from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography  from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { red,green } from '@mui/material/colors';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import ShareIcon from '@mui/icons-material/Share';
import SetMealIcon from '@mui/icons-material/SetMeal';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const SchoolMeals = (props) => {

  const [schoolMeals, setSchoolMeals] = useState([]);

useEffect(() => {
  const fetchSchoolMeals = async () => {
    let storedMeals = [];
    
    try {
      const getSchoolMeals = await fs.collection('household_users')
        .where('email', '==', props.profile.email)
        .get();

        getSchoolMeals.docs.forEach((doc) => {
        const meals = doc.data().schoolMeals;
        if (Array.isArray(meals)) {
          storedMeals = storedMeals.concat(meals);
        }
      });

      setSchoolMeals(storedMeals);
    
    } catch (error) {
      console.error("Error fetching school meals:", error);
    }
  };

  fetchSchoolMeals();
}, [props.profile.uid]);


 const removeMeal =  async (mealToDelete) => {
    console.log(mealToDelete);
    try{
        const querySchoolMeals = await fs.collection('household_users')
        .where('email', '==', props.profile.email)
        .get();

      if (!querySchoolMeals.empty) {
        const mealDocRef = querySchoolMeals.docs[0].ref;

        await mealDocRef.update({
          schoolMeals: FieldValue.arrayRemove(mealToDelete)
        });

        setSchoolMeals(prevChildren =>
          prevChildren.filter(meal => meal !== mealToDelete)
        );

         Swal.fire({
        icon: 'success',
        title: 'Meal Deleted',
        text: 'The Meal has been successfully Deleted',
        showConfirmButton: false,
        timer: 2500
      });
       
    }
  
    } catch (error) {
      console.log(error);
    }
}



const HeadingText = () => {
    return (
    <Typography variant="h4" gutterBottom style={{ textAlign: 'center', color: '#AFBA15', margin: '12px' }}>
      School Meals 
    </Typography>

    );
  }

 return (
 
  <Container>
  <Box sx={{ flexGrow: 1 }}>
   <HeadingText />
    <Grid container spacing={2}> 
      {schoolMeals.map((meal, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}> 
          <Card sx={{ maxWidth: 280, mb: '16px', flex: '1 1 auto' }}>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: '#AFBA15' }} aria-label="recipe">
                  <RestaurantIcon />
                </Avatar>
              }
              action={
                <IconButton aria-label="settings">
                  <MoreVertIcon />
                </IconButton>
              }
              title=""
            />
            <CardMedia
              component="img"
              height="150"
              image={meal.imageURL}
              alt="meal"
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                {meal.mealName}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ textAlign: 'justify' }}
              >
                {meal.mealDescription}
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <IconButton aria-label="add to favorites">
                <RemoveCircleOutlineIcon onClick={() => removeMeal(meal)} />
              </IconButton>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Box>
</Container>
  );

}


const mapStateToProps = (state) => { 
  return {
    profile: state.firebase.profile,      
  };
};

const mapDispatchToProps = {
  SchoolMeals,
};

export default connect(mapStateToProps, mapDispatchToProps)(SchoolMeals);

