import { TextField, InputLabel  } from "@mui/material";

const InputField = ({ type, name, label, value, onChange, id }) => {
  return (
    <>

      <InputLabel
        htmlFor={id}
        sx={{
          position: 'absolute',
          top: -18,
          left: 0,
          backgroundColor: 'white',
          padding: '0 4px',
        }}
      >
        {label}
      </InputLabel>

      <TextField
       margin="dense"
        name={name}
        type= {type}
        fullWidth
        variant="outlined"
        value={value}
        onChange={onChange}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "black",
            },
            "&:hover fieldset": {
              borderColor: "black",
            },
            "&.Mui-focused fieldset": {
              borderColor: "black",
            },
          },
          "& .MuiInputLabel-root": {
            color: "gray",
          },
          "&:hover .MuiInputLabel-root": {
            color: "black",
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: "black",
          },
        }}
      />
    </>
  );
};

export default InputField;
