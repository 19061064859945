import React, { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import TextField from "@mui/material/TextField";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { connect } from "react-redux";
import "./LocationMarker.css";
import CancelIcon from "@mui/icons-material/Cancel";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
const LOCAL_STORAGE_KEY = "farmMapCoordinates";

const LocationMarker = ({ position }) => {
  return position === null ? null : (
    <Marker
      position={position}
      icon={L.icon({
        iconUrl: "https://cdn-icons-png.flaticon.com/512/8259/8259448.png",
        iconSize: [25, 25],
      })}
    />
  );
};

const MapComponent = (propx) => {
  const [coordinates, setCoordinates] = useState([]);

  const [currCordinates, setcurrCordinates] = useState([9.0764785, 9.0764785]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingCoordinates, setLoadingCoordinates] = useState([]);
  const [coordinateMode, setCoordinateMode] = useState(null);
  const mapRef = useRef();

  // useEffect(() => {
  //   setTimeout(() => setLoading(false), 1500);
  // }, [loading]);

  useEffect(() => {
    const storedCoordinates = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEY)
    );
    if (storedCoordinates) {
      setcurrCordinates(storedCoordinates);
    }
    setIsLoading(false);
  }, []);

  const handleSingleSelection = () => {
    setCoordinateMode("single");
    setCoordinates([]);
    setLoadingCoordinates([]);
  };

  const handleMultipleSelection = () => {
    setCoordinateMode("multiple");
    setCoordinates([]);
    setLoadingCoordinates([]);
  };

  const saveSingleCoordinates = async () => {
    if (coordinates.length !== 1) {
      alert("You must add exactly one coordinate in Single mode.");
      return;
    }
    try {
      // Save single coordinate logic
      console.log("Saving single coordinate:", coordinates[0]);
      alert("Single coordinate saved successfully!");
      // Switch to Map Nutrient Tab
      switchToNutrientTab();
    } catch (e) {
      console.error("Error saving single coordinate: ", e);
    }
  };

  const saveMultipleCoordinates = async () => {
    if (coordinates.length !== 3) {
      alert("You must add exactly three coordinates in Multiple mode.");
      return;
    }
    try {
      // Save multiple coordinates logic
      console.log("Saving multiple coordinates:", coordinates);
      alert("Multiple coordinates saved successfully!");
      // Switch to Map Nutrient Tab
      switchToNutrientTab();
    } catch (e) {
      console.error("Error saving multiple coordinates: ", e);
    }
  };

  const switchToNutrientTab = () => {
    // Logic to switch to Map Nutrient Tab
    document.getElementById("nutrient-tab").click();
  };

  const addNewCoordinates = () => {
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(
    //     (position) => {
    //       const { latitude, longitude } = position.coords;
    //       const newCoordinates = { lat: latitude, long: longitude };
    //       setCoordinates([...coordinates, newCoordinates]);
    //     },
    //     (error) => {
    //       console.error("Error getting geolocation: ", error);
    //       alert("Error getting your location. Please try again.");
    //     }
    //   );
    // } else {
    //   alert("Geolocation is not supported by this browser.");
    // }

    if (coordinateMode === "single" && coordinates.length >= 1) {
      alert("You can only add one coordinate in Single mode.");
      return;
    }

    if (coordinateMode === "multiple" && coordinates.length >= 3) {
      alert("You can only add three coordinates in Multiple mode.");
      return;
    }

    setLoadingCoordinates([...loadingCoordinates, true]);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const newCoordinates = { lat: latitude, long: longitude };
          setCoordinates((prevCoordinates) => [
            ...prevCoordinates,
            newCoordinates,
          ]);
          setLoadingCoordinates((prevLoading) => {
            const newLoading = [...prevLoading];
            newLoading[newLoading.length - 1] = false;
            return newLoading;
          });
        },
        (error) => {
          console.error("Error getting geolocation: ", error);
          alert("Error getting your location. Please try again.");
          setLoadingCoordinates((prevLoading) => {
            const newLoading = [...prevLoading];
            newLoading[newLoading.length - 1] = false;
            return newLoading;
          });
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
      setLoadingCoordinates((prevLoading) => {
        const newLoading = [...prevLoading];
        newLoading[newLoading.length - 1] = false;
        return newLoading;
      });
    }
  };

  const deleteCoordinates = (index) => {
    setCoordinates(coordinates.filter((_, i) => i !== index));
  };

  const handleReset = () => {
    setCoordinateMode(null);
    setCoordinates([]);
  };

  const handleCoordinateChange = (index, field, value) => {
    const newCoordinates = coordinates.map((coord, i) =>
      i === index ? { ...coord, [field]: value } : coord
    );
    setCoordinates(newCoordinates);
  };

  const getLiveLocation = () => {
    alert("Allow application to use your Live Location");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setcurrCordinates([latitude, longitude]);
          localStorage.setItem(
            LOCAL_STORAGE_KEY,
            JSON.stringify([latitude, longitude])
          );
          if (mapRef.current) {
            mapRef.current.setView([latitude, longitude], 13);
          }
        },
        (error) => {
          console.error("Error getting geolocation: ", error);
          alert("Error getting your location. Please try again.");
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="main">
        <div className="map" style={{ position: "relative" }}>
          <MapContainer
            center={currCordinates}
            zoom={13}
            style={{ height: "100%", width: "100%" }}
            whenCreated={(mapInstance) => (mapRef.current = mapInstance)}
          >
            <button
              onClick={getLiveLocation}
              style={{
                position: "absolute",
                bottom: "10px",
                left: "10px",
                zIndex: 1000,
              }}
            >
              Get Live Location
            </button>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <LocationMarker position={currCordinates} />
          </MapContainer>
        </div>

        <div className="coords" style={{}}>
          <h3>Setup Farm Location</h3>

          {!coordinateMode && (
            <>
              <button onClick={handleSingleSelection}>Single Cordinates</button>
              <button onClick={handleMultipleSelection}>
                Multiple Cordinates
              </button>
            </>
          )}
          {coordinates.map((coord, index) => (
            <div className="coord-pair" key={index}>
              {loadingCoordinates[index] ? (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <TextField
                    placeholder="Latitude"
                    value={coord.lat}
                    onChange={(e) =>
                      handleCoordinateChange(index, "lat", e.target.value)
                    }
                  />
                  <TextField
                    placeholder="Longitude"
                    value={coord.long}
                    onChange={(e) =>
                      handleCoordinateChange(index, "long", e.target.value)
                    }
                  />
                </>
              )}
              <CancelIcon onClick={() => deleteCoordinates(index)} />
            </div>
          ))}
          {(coordinateMode === "single" || coordinateMode === "multiple") && (
            <>
              <button id="new-coordinates-button" onClick={addNewCoordinates}>
                New Coordinates
              </button>

              {coordinateMode === "single" && (
                <>
                  <button
                    id="save-coordinates-button"
                    onClick={saveSingleCoordinates}
                  >
                    Save Single Coordinates
                  </button>
                </>
              )}
              {coordinateMode === "multiple" && (
                <>
                  <button
                    id="save-coordinates-button"
                    onClick={saveMultipleCoordinates}
                  >
                    Save Multiple Coordinates
                  </button>
                </>
              )}
              <button onClick={handleReset}>Change Mode</button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.data.getData,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MapComponent);
