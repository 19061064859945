import { connect } from "react-redux";
import { PageWrap } from "../../../../components/SubComponents/PageWrap";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  addSupplier,
  removeSuppliers,
} from "../../../../store/actions/publicSector/councilActions";
import React, { useState, useEffect } from "react";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import Stack from "@mui/material/Stack";
import {
  Container,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Box,
  Grid,
} from "@material-ui/core";

const SupplierManagement = (props) => {
  console.log(props);
  const { governmentUsers, profile, addSupplier, removeSuppliers } = props;

  const [open, setOpen] = useState(false);
  const [supplier, setSupplier] = useState({
    name: "",
    contact: "",
    email: "",
    address: "",
    supplies: [],
  });
  const [product, setProduct] = useState({
    name: "",
    code: "",
    storage: "",
    category: "",
    nutrition: "",
    allergy: "",
  });
  const [supplierList, setSupplierList] = useState([]);

  useEffect(() => {
    if (
      governmentUsers &&
      profile.uid &&
      governmentUsers[profile.uid] &&
      governmentUsers[profile.uid].suppliers
    ) {
      extractSuppliers(governmentUsers[profile.uid].suppliers);
    }
  }, [governmentUsers, profile.uid]);

  const extractSuppliers = (data) => {
    const supplierSet = new Set();
    data.forEach((supplier) => supplierSet.add(JSON.stringify(supplier)));
    const supplierArray = Array.from(supplierSet).map((supplier) =>
      JSON.parse(supplier)
    );
    setSupplierList(supplierArray);
  };

  const handleAddSupplierToBackend = () => {
    addSupplier(supplier, profile.uid)
      .then(() => {
        console.log("Supplier Added");
        // setSupplierList((prevSupplierList) => [...prevSupplierList, supplier]);
        handleClose();
      })
      .catch((error) => {
        console.error("Error adding supplier:", error);
      });
  };

  const handleRemoveSupplier = (supplierName) => {
    removeSuppliers(supplierName, profile.uid)
      .then(() => {
        setSupplierList((prevSupplierList) =>
          prevSupplierList.filter((supplier) => supplier.name !== supplierName)
        );
      })
      .catch((error) => {
        console.error("Error removing supplier:", error);
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSupplierChange = (e) => {
    const { name, value } = e.target;
    setSupplier({ ...supplier, [name]: value });
  };

  const handleProductChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handleAddProduct = () => {
    setSupplier({ ...supplier, supplies: [...supplier.supplies, product] });
    setProduct({
      name: "",
      code: "",
      storage: "",
      category: "",
      nutrition: "",
      allergy: "",
    });
  };

  const handleAddSupplier = () => {
    handleAddSupplierToBackend();
    setSupplier({
      name: "",
      contact: "",
      email: "",
      address: "",
      supplies: [],
    });
  };

  return (
    <>
      <PageWrap goTo="/account">
        <Container>
          <Button
            style={{
              backgroundColor: "#AFBA15",
              color: "white",
            }}
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
          >
            Add Supplier
          </Button>

          <Dialog open={open} onClose={handleClose}>
            <DialogTitle
              style={{
                backgroundColor: "#AFBA15",
                color: "white",
              }}
            >
              Add Supplier
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                name="name"
                label="Name"
                type="text"
                fullWidth
                variant="outlined"
                value={supplier.name}
                onChange={handleSupplierChange}
              />
              <TextField
                margin="dense"
                name="contact"
                label="Contact"
                type="text"
                fullWidth
                variant="outlined"
                value={supplier.contact}
                onChange={handleSupplierChange}
              />
              <TextField
                margin="dense"
                name="email"
                label="Email"
                type="email"
                fullWidth
                variant="outlined"
                value={supplier.email}
                onChange={handleSupplierChange}
              />
              <TextField
                margin="dense"
                name="address"
                label="Address"
                type="text"
                fullWidth
                variant="outlined"
                value={supplier.address}
                onChange={handleSupplierChange}
              />
              <Typography
                style={{
                  margin: "0px",
                  marginTop: "15px",
                }}
                variant="h6"
                gutterBottom
              >
                Add Product
              </Typography>
              <TextField
                margin="dense"
                name="name"
                label="Product Name"
                type="text"
                fullWidth
                variant="outlined"
                value={product.name}
                onChange={handleProductChange}
              />
              <TextField
                margin="dense"
                name="code"
                label="Product Code"
                type="text"
                fullWidth
                variant="outlined"
                value={product.code}
                onChange={handleProductChange}
              />
              <TextField
                margin="dense"
                name="storage"
                label="Storage Condition"
                type="text"
                fullWidth
                variant="outlined"
                value={product.storage}
                onChange={handleProductChange}
              />
              <TextField
                margin="dense"
                name="category"
                label="Category"
                type="text"
                fullWidth
                variant="outlined"
                value={product.category}
                onChange={handleProductChange}
              />
              <TextField
                margin="dense"
                name="nutrition"
                label="Nutrition Information"
                type="text"
                fullWidth
                variant="outlined"
                value={product.nutrition}
                onChange={handleProductChange}
              />
              <TextField
                margin="dense"
                name="allergy"
                label="Allergy Information"
                type="text"
                fullWidth
                variant="outlined"
                value={product.allergy}
                onChange={handleProductChange}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddProduct}
                style={{
                  backgroundColor: "#AFBA15",
                  color: "white",
                  marginTop: 10,
                }}
              >
                Add Product
              </Button>
            </DialogContent>
            <DialogActions>
              <Button
                style={{
                  backgroundColor: "#AFBA15",
                  color: "white",
                }}
                onClick={handleClose}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                style={{
                  backgroundColor: "#AFBA15",
                  color: "white",
                }}
                onClick={handleAddSupplier}
                color="primary"
              >
                Add Supplier
              </Button>
            </DialogActions>
          </Dialog>

          {supplierList.length === 0 ? (
            <Typography
              variant="h6"
              align="center"
              color="textSecondary"
              style={{ marginTop: 20 }}
            >
              No Suppliers
            </Typography>
          ) : (
            <List subheader={<li />}>
              {supplierList.map((supplier, index) => (
                <li key={`supplier-${index}`} style={{ marginTop: "15px" }}>
                  <ul>
                    <ListSubheader
                      style={{
                        padding: "5px",
                        fontWeight: "bold",
                        color: "black",
                        backgroundColor: "#AFBA15",
                        fontSize: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {supplier.name}
                      <Stack direction={"row"} spacing={2}>
                        <EditIcon
                          onClick={() => console.log("Edit Suppliers")}
                          sx={{}}
                        />
                        <DeleteIcon
                          onClick={() => handleRemoveSupplier(supplier.name)}
                        />
                      </Stack>
                    </ListSubheader>
                    <ListItemText
                      primary={`Contact: ${supplier.contact}`}
                      secondary={`Email: ${supplier.email}\nAddress: ${supplier.address}`}
                    />

                    <Box sx={{ flexGrow: 1 }}>
                      <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                      >
                        {supplier.supplies.map((product, idx) => (
                          <Grid
                            item
                            xs={2}
                            sm={4}
                            md={4}
                            key={`product-${idx}`}
                          >
                            <ListItem
                              key={`product-${idx}`}
                              style={{ padding: "0px" }}
                            >
                              <ListItemText
                                primaryTypographyProps={{
                                  style: { fontWeight: "bold" },
                                }}
                                primary={product.name}
                                secondary={
                                  <>
                                    <Typography
                                      component="span"
                                      variant="body2"
                                      color="textPrimary"
                                    >
                                      Code: {product.code}
                                    </Typography>
                                    <br />
                                    <Typography
                                      component="span"
                                      variant="body2"
                                      color="textPrimary"
                                    >
                                      Storage: {product.storage}
                                    </Typography>
                                    <br />
                                    <Typography
                                      component="span"
                                      variant="body2"
                                      color="textPrimary"
                                    >
                                      Category: {product.category}
                                    </Typography>
                                    <br />
                                    <Typography
                                      component="span"
                                      variant="body2"
                                      color="textPrimary"
                                    >
                                      Nutrition: {product.nutrition}
                                    </Typography>
                                    <br />
                                    <Typography
                                      component="span"
                                      variant="body2"
                                      color="textPrimary"
                                    >
                                      Allergy: {product.allergy}
                                    </Typography>
                                  </>
                                }
                              />
                            </ListItem>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </ul>
                </li>
              ))}
            </List>
          )}
        </Container>
      </PageWrap>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    governmentUsers: state.firestore.data.government_users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSupplier: (data, uid) => dispatch(addSupplier(data, uid)),
    removeSuppliers: (data, uid) => dispatch(removeSuppliers(data, uid)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    if (!props.auth || props.auth.isEmpty) return [];
    return [
      {
        collection: "government_users",
        storeAs: "government_users",
      },
    ];
  })
)(SupplierManagement);

// {
//   name: "Supplier 1",
//   contact: "1234567890",
//   email: "supplier1@example.com",
//   address: "123 Supplier St.",
//   supplies: [
//     {
//       name: "Apple",
//       code: "AP123",
//       storage: "Fresh",
//       category: "Fruits",
//       nutrition: "Vitamins A, C",
//       allergy: "None",
//     },
//     {
//       name: "Cheese",
//       code: "CH456",
//       storage: "Refrigerated",
//       category: "Dairy",
//       nutrition: "Calcium",
//       allergy: "Milk",
//     },
//     {
//       name: "Apple",
//       code: "AP123",
//       storage: "Fresh",
//       category: "Fruits",
//       nutrition: "Vitamins A, C",
//       allergy: "None",
//     },
//     {
//       name: "Cheese",
//       code: "CH456",
//       storage: "Refrigerated",
//       category: "Dairy",
//       nutrition: "Calcium",
//       allergy: "Milk",
//     },
//   ],
// },
// {
//   name: "Supplier 2",
//   contact: "0987654321",
//   email: "supplier2@example.com",
//   address: "456 Supplier Ave.",
//   supplies: [
//     {
//       name: "Chicken Breast",
//       code: "CB789",
//       storage: "Frozen",
//       category: "Meat",
//       nutrition: "Protein",
//       allergy: "None",
//     },
//     {
//       name: "Canned Beans",
//       code: "CB101",
//       storage: "Canned",
//       category: "Processed Food",
//       nutrition: "Fiber",
//       allergy: "None",
//     },
//     {
//       name: "Chicken Breast",
//       code: "CB789",
//       storage: "Frozen",
//       category: "Meat",
//       nutrition: "Protein",
//       allergy: "None",
//     },
//     {
//       name: "Canned Beans",
//       code: "CB101",
//       storage: "Canned",
//       category: "Processed Food",
//       nutrition: "Fiber",
//       allergy: "None",
//     },
//   ],
// },
