import React, { useState, useEffect } from 'react';
import { Form, Table } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { PageWrap } from '../../../SubComponents/PageWrap';
import { fs, FieldPath  } from "../../../../config/fbConfig";
import { Add, Delete } from "@mui/icons-material";
import  "../../../../custom.css";
import InputField from "./InputField"; 
import {
  Box,
  Container,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Typography

} from "@mui/material";

const ConnectSchool = (props) => {
  const [schoolCode, setSchoolCode] = useState('');
  const [getSchools, setGetSchools] = useState([]);
  const [schoolMeals, setSchoolMeals] = useState([]);

useEffect(() => {
    const schoollist = async () => {
      try {
      
        const fetchSchools = await fs.collection('houseHoldConnectSchool')
                                      .where('householdID', '==', props.profile.uid)
                                      .get();
        const storeEmails = [];

        fetchSchools.docs.forEach(doc => storeEmails.push(doc.data().schoolEmail));

        if (storeEmails.length === 0) {
          setGetSchools([]);
          console.log('School Not Found.');
          return;
        }

        const schoolDetailsPromises = storeEmails.map(email =>
          fs.collection('academic_users')
            .where('email', '==', email)
            .get()
        );

        const schoolDetailsSnapshots = await Promise.all(schoolDetailsPromises);

        const allSchools = schoolDetailsSnapshots.flatMap(snapshot =>
          snapshot.docs.map(doc => doc.data())
        );

        setGetSchools(allSchools);

      } catch (error) {
        console.error('Error fetching school details:', error);
      }
    };

    schoollist();
  }, [props.profile.uid]);

  
  const handleConnectSchool = async (e) => {

    e.preventDefault();

      if (!schoolCode ) {
      return Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'School ID is required.',
        showConfirmButton: false,
        timer: 2500
      });
    }

    const checkSchoolCode = await fs.collection('academic_users')
    .where('schoolCode', '==', schoolCode)
    .get();

     //console.log(checkSchoolCode);
    if(checkSchoolCode.empty){
       Swal.fire({
        title: 'Error!',
        text: 'Invalid School Code!!!',
        icon: 'error',
        showConfirmButton: false,
        timer: 2500
      });
    }

     let schoolID = null;
    let schoolEmail = null;

  checkSchoolCode.forEach((doc) => {
    schoolID = doc.id;
    schoolEmail = doc.data().email; 
  });

    //console.log("School-ID: ",schoolID);

    const data = {
      householdID: props.profile.uid,
      schoolID: schoolID,
      schoolEmail: schoolEmail,
      status: true
    };

  
    try {

    const checkSchool = await  fs.collection('houseHoldConnectSchool')
      .where('schoolID', '==', schoolID)
      .get()

      if(!checkSchool.empty){
          return  Swal.fire({
            title: 'Error!',
            text: 'You have connected to this school already',
            icon: 'error',
            showConfirmButton: false,
            timer: 2500
          });
           
      }
      
      await fs.collection('houseHoldConnectSchool').add(data);

      Swal.fire({
        title: 'Success!',
        text: 'School connected successfully.',
        icon: 'success',
        showConfirmButton: false,
        timer: 2500
      });

      const fetchMeals = await fs.collection('menus')
      .where('restaurantID', '==', schoolID)
      .get();
      
      const storeMeals = [];

      fetchMeals.forEach((doc) => {
        const data = doc.data();
        storeMeals.push({
            mealName: data.meal,
            mealDescription: data.mealDescription,
            imageURL: data.imageURL
        });
      });

      const saveMeals = await fs.collection('household_users')
      .doc(props.profile.uid)
      .set({schoolMeals: storeMeals}, {merge:true});

      setSchoolCode('');

    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: error.message,
        icon: 'error',
        showConfirmButton: false,
        timer: 2500
      });
    }  

  };

  const ConnectSchools = () => {
  
  return (
    <>
    <h4> Connected Schools </h4>
    <Table  hover>
   <thead className="custom-head" >
    <tr>
      <th>S/No</th>
      <th>School Name</th>
      <th>Email</th>
      <th>Disconnect</th>
    </tr>
  </thead>
  <tbody>
    {
      getSchools.map((school, index) => (
      <tr key={index}>
      <td>{index + 1}</td>
      <td>{school.name}</td>
      <td>{school.email}</td>
      <td><IconButton>  <Delete style={{ color: 'red' }} /> </ IconButton> </td>
    </tr>

      ))
    }
  
  </tbody>
</Table>
</>
);

  }

  return (
    <>
      
   
    <Container sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      flexDirection: 'column',
      margin: '0 auto'
    }}>

     <Typography variant="h4" gutterBottom style={{textAlign: 'center', color: '#AFBA15', margin: '12px'}}>
    Connect School
     </Typography>

   <FormControl sx={{   width: 600 }} variant="outlined" margin="dense">
       <InputField
               type="text"
              name={"Enter school code"}
              label={"Enter School Code"}
              value={schoolCode}
              onChange={(e) => setSchoolCode(e.target.value)}
        />
    </FormControl>

      <Button
          sx={{
            backgroundColor: "#AFBA15",
            color: "white",
            padding: "10px 20px",
            margin: "14px",
            fontWeight: "bold"
          }}
           onClick={handleConnectSchool}
          >
            Connect School Code
         
      </Button>

    </Container>

    
    < ConnectSchools />
   </>
  );


};

const mapStateToProps = (state) => {
  return {
    profile: state.firebase.profile,
  };
};

export default connect(mapStateToProps)(ConnectSchool);