import { connect } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

// PIE CHART
import { Pie } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { Stack } from "@mui/material";

function createData(name, fat) {
  return { name, fat };
}

const rows = [
  createData("Potassuim Requirement", 60),
  createData("Nitrogen Requirement", 23),
  createData("Phosphorus Requirement", 26),
  createData("Magnesium Requirment", 30),
];
function createSecondData(crops, itemNumber) {
  return { crops, itemNumber };
}

const secondRows = [
  createSecondData("Tomatoe", 60),
  createSecondData("Lettuce", 23),
  createSecondData("Carrot", 26),
  createSecondData("Onions", 30),
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#afba15",
    color: theme.palette.common.black,
    fontSize: "20px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

// DUMMY DATA IS BRING USED HERE PENDING WHEN THE GCP API IS READY FOR CONSUMPTION
const FarmNutrient = () => {
  const recommendedCrops = [];
  return (
    <>
      <Stack spacing={2} direction={"column"}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 400 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ color: "white", fontSize: "20px" }}>
                  Nutrients
                </StyledTableCell>
                <StyledTableCell align="right">&nbsp;</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{row.fat}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/*  */}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 400 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ color: "white", fontSize: "20px" }}>
                  Crop Demand
                </StyledTableCell>
                <StyledTableCell align="right">&nbsp;Amount</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {secondRows.map((row) => (
                <TableRow
                  key={row.crops}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.crops}
                  </TableCell>
                  <TableCell align="right">{row.itemNumber}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <div style={{ marginTop: "20px" }}>
        <h3>Recommended Crops</h3>
        {recommendedCrops.length === 0 ? (
          <p>No Recommended Crops</p>
        ) : (
          <ul>
            {recommendedCrops.map((crop, index) => (
              <li key={index}>{crop}</li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

const NutrientPieChart = () => {
  const data = {
    labels: rows.map((row) => row.name),
    datasets: [
      {
        label: "Nutrient Requirements",
        data: rows.map((row) => row.amount),
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"],
      },
    ],
  };
  return <Pie data={data} />;
};

const mapStateToProps = (state) => {
  return {
    data: state.data.getData,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FarmNutrient);
