import React from "react";
import { connect } from "react-redux";

export const ConsultantNotes = (props) => {
	return <div>consultantNotes</div>;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ConsultantNotes);
