import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { fs } from "../../../../config/fbConfig"
import InputField from "./InputField";
import {
	Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,

} from "@mui/material"; 


const ChildMealPlan = () => {

  const [mondayMeal, setMondayMeal] = useState('');
  const [tuesdayMeal, setTuesdayMeal] = useState('');
  const [wednesdayMeal, setWednesdayMeal] = useState('');
  const [thursdayMeal, setThursdayMeal] = useState('');
  const [fridayMeal, setFridayMeal] = useState('');
  const handleChange = (event) => {
    //setAge(event.target.value);
  };

 return (
    <Box style={{ 
    	display: 'flex', 
	    justifyContent: 'center', 
	    alignItems: 'center', 
	    flexDirection: 'column',
	    margin: '0 auto'
    }}>
    <h3 style={{textAlign: 'center', color: '#AFBA15', margin: '14px'}}> Child Meal Plan </h3>
      <FormControl sx={{   width: 600 }} variant="outlined" margin="dense">
        <InputLabel id="demo-simple-select-label">Monday</InputLabel>
        <Select
          labelId="monday-label"
          name="mondayMeal"
          value={mondayMeal}
          label="Monday"
          onChange={(e) => setMondayMeal(e.target.value)}
        >
        <MenuItem value="All">All</MenuItem>
        <MenuItem value="Breakfast">Breakfast</MenuItem>
          <MenuItem value="Lunch">Lunch</MenuItem>
          <MenuItem value="Dinner">Dinner</MenuItem>
        </Select>
      </FormControl>

      <FormControl sx={{   width: 600 }} variant="outlined" margin="dense">
        <InputLabel id="demo-simple-select-label">Tuesday</InputLabel>
        <Select
          labelId="tuesday-label"
          name="tuesday"
          value={tuesdayMeal}
          label="Monday"
          onChange={(e) => setTuesdayMeal(e.target.value)}
        >
           <MenuItem value="All">All</MenuItem>
           <MenuItem value="Breakfast">Breakfast</MenuItem>
          <MenuItem value="Lunch">Lunch</MenuItem>
          <MenuItem value="Dinner">Dinner</MenuItem>
        </Select>
      </FormControl>

      <FormControl sx={{   width: 600 }} variant="outlined" margin="dense">
        <InputLabel id="demo-simple-select-label">Wednesday</InputLabel>
        <Select
          labelId="wednesday-label"
          name="wednesday"
          value={wednesdayMeal}
          label="Wednesday"
          onChange={(e) => setWednesdayMeal(e.target.value)}
        >
         <MenuItem value="All">All</MenuItem>
          <MenuItem value="Breakfast">Breakfast</MenuItem>
          <MenuItem value="Lunch">Lunch</MenuItem>
          <MenuItem value="Dinner">Dinner</MenuItem>
        </Select>
      </FormControl>

      <FormControl sx={{   width: 600 }} variant="outlined" margin="dense">
        <InputLabel id="demo-simple-select-label">Thursday</InputLabel>
        <Select
          labelId="thursday-label"
          name="thursday"
          value={thursdayMeal}
          label="Thursday"
          onChange={(e) => setThursdayMeal(e.target.value)}
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="Breakfast">Breakfast</MenuItem>
          <MenuItem value="Lunch">Lunch</MenuItem>
          <MenuItem value="Dinner">Dinner</MenuItem>
        </Select>
      </FormControl>

      <FormControl sx={{   width: 600 }} variant="outlined" margin="dense">
        <InputLabel id="demo-simple-select-label">Friday</InputLabel>
        <Select
          labelId="friday-label"
          id="friday"
          value={fridayMeal}
          label="Friday"
          onChange={(e) => setFridayMeal(e.target.value)}
        >
          <MenuItem value="All">All</MenuItem>
           <MenuItem value="Breakfast">Breakfast</MenuItem>
          <MenuItem value="Lunch">Lunch</MenuItem>
          <MenuItem value="Dinner">Dinner</MenuItem>
        </Select>
      </FormControl>

      <Button
          sx={{
	          backgroundColor: "#AFBA15",
	          color: "white",
	          padding: "12px",
	          margin: "14px"
	        }}
	      
          >
          Add Meal Plan
      </Button>

    </Box>
  );

}


const mapStateToProps = (state) => { 
  return {
    profile: state.firebase.profile,      
  };
};

const mapDispatchToProps = {
  ChildMealPlan,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChildMealPlan);

