import React from 'react';

import './Label.css';

const Label = (props) => {


  return (
    <div className='Lbl'>

        <label>My products</label>
  

    </div>
  );
};

export default Label;
