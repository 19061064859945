import React, { useState, useEffect } from 'react';
import { Form, Table } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { PageWrap } from '../../../SubComponents/PageWrap';
import { fs, FieldPath, FieldValue  } from "../../../../config/fbConfig";
import { Add, Delete } from "@mui/icons-material";
import  "../../../../custom.css";
import InputField from "./InputField"; 
import {
  Box,
  Container,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton, 
  Typography

} from "@mui/material";

const HouseChildren = (props) => {
  const [schoolCode, setSchoolCode] = useState('');
  const [getSchools, setGetSchools] = useState([]);
  const [houseChildren, setHouseChildren] = useState([]);

  useEffect(() => {
    const fetchChildren = async () => {
      try {
        const fetchHouseholds = await fs.collection('household_users')
          .where('email', '==', props.profile.email) 
          .get();

        if (!fetchHouseholds.empty) {
          const userDoc = fetchHouseholds.docs[0];
          const data = userDoc.data();

          if (data.children && Array.isArray(data.children)) {
            setHouseChildren(data.children);
          }
        }
        console.log(houseChildren)
      } catch (error) {
        console.error("Error fetching children data:", error);
      } 

    };

    fetchChildren();
  }, [props.profile.uid]);


  const removeChild =  async (childToDelete) => {
    console.log(childToDelete);
    try{
        const fetchHouseholds = await fs.collection('household_users')
        .where('email', '==', props.profile.email)
        .get();

      if (!fetchHouseholds.empty) {
        const userDocRef = fetchHouseholds.docs[0].ref;

        await userDocRef.update({
          children: FieldValue.arrayRemove(childToDelete)
        });

        setHouseChildren(prevChildren =>
          prevChildren.filter(child => child !== childToDelete)
        );

         Swal.fire({
        icon: 'success',
        title: 'Child Deleted',
        text: 'The child has been successfully Deleted',
        showConfirmButton: false,
        timer: 2500
      });
       
    }
  
    } catch (error) {
      console.log(error);
    }

  };

  const RenderChildren = () => {
  
  return (
    <>
   
    <Table  hover>
   <thead className="custom-head" >
    <tr>
      <th>S/No</th>
      <th>Firstname</th>
      <th>Lastname</th>
       <th>Gender</th>
      <th>DOB</th>
      <th>Class</th>
       <th>School</th>
       <th>Delete</th>
    </tr>
  </thead>
  <tbody>
    {
      houseChildren.map((child, index) => (
      <tr key={index}>
      <td>{index + 1}</td>
      <td>{child.firstname}</td>
      <td>{child.lastname}</td>
      <td>{child.gender}</td>
      <td>{child.dob}</td>
      <td>{child.childClass}</td>
      <td>{child.schoolName}</td>
      <td><IconButton onClick={ () => removeChild(child) }>  <Delete style={{ color: 'red' }} /> </ IconButton> </td>
    </tr>

      ))
    }
  
  </tbody>
</Table>
</>
);

  }

  return (
    <>
      
   
    <Container sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      flexDirection: 'column',
      margin: '0 auto'
    }}>

     <Typography variant="h4" gutterBottom style={{textAlign: 'center', color: '#AFBA15', margin: '12px'}}>
   House Children
     </Typography>

     < RenderChildren />

    </Container>

    
   
   </>
  );


};

const mapStateToProps = (state) => {
  return {
    profile: state.firebase.profile,
  };
};

export default connect(mapStateToProps)(HouseChildren);