import React, { useState, useEffect } from 'react';
import { Form, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { addChild } from '../../../../store/actions/actions';
import { collection, addDoc } from "firebase/firestore"; 
import { fs, FieldValue, arrayUnion } from "../../../../config/fbConfig";
import InputField from "./InputField";
import {
  Box,
  Container,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Typography

} from "@mui/material"; 

const AddChild = (props) => {
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [dob, setDob] = useState('');
  const [childClass, setChildClass] = useState('');
  const [gender, setGender] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedClass, setSelectedClass] = useState('');
  const [availableClasses, setAvailableClasses] = useState([]);
  // const [filteredClasses, setFilteredClasses] = useState([]);

 useEffect(() => {
    const fetchClasses = async () => {
      try {
        const classesSnapshot = await fs.collection('classes').get();
        const classesData = classesSnapshot.docs.map(doc => doc.data().class); 
        setAvailableClasses(classesData);
        // console.log('classes: ' + classesData)
      } catch (error) {
        console.error('Error fetching classes:', error);
      }
    };
     
    fetchClasses();
  }, []);


  const handleAddChild = async () => {

     if (!firstname || !lastname || !dob  || !gender) {
      return Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'All fields are required.',
        showConfirmButton: false,
        timer: 2500
      });
    }

    let schoolID = null;
    let schoolName = null;
    let schoolEmail = null;

    const fecthID = await fs.collection('houseHoldConnectSchool')
    .where('householdID', '==', props.profile.uid)
    .get();

     fecthID.forEach((doc) => {
        schoolID = doc.schoolID;
        schoolEmail = doc.data().schoolEmail; 
     })

    const fetchSchool = await fs.collection('academic_users')
    .where('email', '==', schoolEmail)
    .get();

     fetchSchool.forEach((doc) => {
        schoolName = doc.data().name; 
     })

    try {
       
      const fetchHouseHold = await fs.collection('household_users')
      .where('email', '==', props.profile.email)
      .get();

      if (fetchHouseHold.empty) {
        console.error("No user found with the given email.");
        return;
      }

      const childDocRef = fetchHouseHold.docs[0].ref;

    const addChild = await childDocRef.update({
    'children': arrayUnion({
      firstname: firstname,
      lastname: lastname,
      dob: dob,
      childClass: selectedClass,
      gender: gender,
      schoolName: schoolName,
    })
  });
    
      Swal.fire({
        icon: 'success',
        title: 'Child Added',
        text: 'The child has been successfully added to the school',
        showConfirmButton: false,
        timer: 2500
      });

      // Optionally, you can also add the child to the Redux store if needed
      //props.addChild(child);

      setFirstname('');
      setLastname('');
      setDob('');
      setChildClass('');
      setGender('');
      setSelectedClass('');
      // setShowModal(false);
    } catch (error) {
      console.error('Error adding child:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'There was an error. Please try again later.',
        showConfirmButton: false,
        timer: 2500
      });
    }
  };

  return (
   
    <Container sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      flexDirection: 'column',
      margin: '0 auto'
    }}>

      <Typography variant="h4" gutterBottom style={{textAlign: 'center', color: '#AFBA15', margin: '12px'}}>
     Add Child Details
     </Typography>
     
    <FormControl sx={{   width: 600 }} variant="outlined" margin="dense">
       <InputField
               type="text"
              name={"Firstname"}
              label={"Firstname"}
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
        />
    </FormControl>

    <FormControl sx={{   width: 600 }} variant="outlined" margin="dense">
       <InputField
             type="text"
            name={"Lastname"}
            label={"Lastname"}
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
      />
    </FormControl>

    <FormControl sx={{   width: 600 }} variant="outlined" margin="dense">
       <InputField
            type="date"
            name={"Date Of Birth"}
            label={"Date Of Birth"}
            value={dob}
            onChange={(e) => setDob(e.target.value)}
            id="dob"
      />
    </FormControl>

    <FormControl sx={{   width: 600 }} variant="outlined" margin="dense">
     <InputLabel>
        Child Class
      </InputLabel>
       <Select
          type={"text"}
          name={"selectedClass"}
          value={selectedClass}
          label= "Child Class"
          onChange={(e) => setSelectedClass(e.target.value)}
        >
        <MenuItem value="">Select Child Class...</MenuItem>
        {availableClasses.map((cls, index) => (
            <MenuItem key={index} value={cls}>{cls}</MenuItem>
              ))
        }
        </Select>
    </FormControl>


    <FormControl sx={{   width: 600 }} variant="outlined" margin="dense">
       <InputField
            type="text"
            name={"Gender"}
            label={"Gender"}
            value={gender}
            onChange={(e) => setGender(e.target.value)}
      />
    </FormControl>

     <Button
          sx={{
            backgroundColor: "#AFBA15",
            color: "white",
            padding: "10px 20px",
            margin: "14px",
            fontWeight: "bold"
          }}
           onClick={handleAddChild}
          >
          Add Child
         
      </Button>

    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.firebase.profile,
    isLoading: state.child.isLoading,
  };
};

const mapDispatchToProps = {
  addChild,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddChild);