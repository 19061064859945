import React, { useState, useEffect } from "react";

import { useTranslation, Trans } from "react-i18next";
import { Row, Col } from "react-bootstrap";

import MealsBoxRecipe from "./MealsBoxPlan";
import { connect } from "react-redux";
import { getMenus } from "../../../../../store/actions/marketplaceActions/restaurantData";
import { AddMenuModal } from "./Icons/AddMenuModal";
import  AddCouncilMealModal  from "./Icons/AddCouncilMealModal";
import { Dropdown } from "./../../../../SubComponents/Dropdown";

const SavedMeals = (props) => {
  const { t } = useTranslation();

  const [menus, setMenus] = useState([]);
  const [weeklyMeals, setWeeklyMeals] = useState([]);

  const [showAddMenu, setShowAddMenu] = useState(false);
  const [showCouncilMenu, setShowCouncilMenu] = useState(false);
  const [menuSection, setMenuSection] = useState("All");

  //this sends data request
  useEffect(() => {
    props.getMenus();
  }, []);

  const menuSections = [
    "All",
    "Any",
    "Breakfast",
    "Lunch",
    "Dinner",
    "Snack",
    "Brunch",
    "Large Plates",
    "Small Plates",
    "Sides",
    "Dessert",
  ];
  useEffect(() => {}, [props.Menus]);
  console.log(props.Menus, `these are the menus`);

  useEffect(() => {
    if (menuSection === "All") {
      setMenus(props.Menus);
    } else {
      const newMenu = props.Menus.filter((menu) => {
        return menu.menuSection === menuSection;
      });
      setMenus(newMenu);
    }
  }, [menuSection, props.Menus]);

  let allMenus =
    menus === null ? (
      "...loading"
    ) : menus?.length > 0 ? (
      // props.Menus.map((menu) => {
      // return (
      <MealsBoxRecipe
        // forceUpdate={forceUpdate}
        onChange={props.onChange}
        meals={menus}
      />
    ) : (
      <div>menu list empty</div>
    );

  return (
    <>
      <Row className="row">
        <Col md="8" className=" basic-title-left mb-3">
          {t("description.restaurant_menu")}
        </Col>
        <Col md="2" style={{ display: "flex", alignItems: "baseline" }}>
          New Menu
          <AddMenuModal
            value={props.value}
            show={showAddMenu}
            setShow={setShowAddMenu}
          />
        </Col>
        <Col md="2" style={{ display: "flex", alignItems: "baseline" }}>
          Council Menu
          <AddCouncilMealModal
            value={props.value}
            show={showCouncilMenu}
            setShow={setShowCouncilMenu}
          />
        </Col>
      </Row>
      <Row>
        <Col md="3"> Filter by </Col>
        <Col md="3">
          <Dropdown
            id="menu-section"
            styling="green dropdown-input"
            data={menuSection}
            items={menuSections}
            function={(e) => setMenuSection(e)}
          />
        </Col>
      </Row>
      <div>{allMenus}</div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    Menus: state.restaurant.savedMenus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMenus: () => dispatch(getMenus()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SavedMeals);
