import React, { useEffect, useState } from "react";
import { getFarmerData } from "../../../../../store/actions/marketplaceActions/farmPlanData";
import { connect } from "react-redux";
import LoadingScreen from "../../../../SubComponents/Loading/LoadingScreen";
import { PageWrapFarm } from "../../../../SubComponents/PageWrapFarm";
import Aquaculture from "./FarmCategories/Aquaculture";
import Forestry from "./FarmCategories/Forestry";
import Insect from "./FarmCategories/Insect";
import Livestock from "./FarmCategories/Livestock";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import LocationMarker from "../FarmMap/LocationMarker";
import FarmNutrient from "../Farm Nutrient/FarmNutrient";

import "./FarmPlan.css";

import Horticulture from "./FarmCategories/Horticulture";

function FarmPlan(props) {
  //handles loading page
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1500);
  });
  useEffect(() => {
    props.getFarmerData();
  }, []);

  console.log(props.data);

  const Control = () => {
    switch (props.data[0].sector) {
      default:
      case "Horticulture":
        return <Horticulture />;
      case "Livestock":
        return <Livestock />;
      case "Aquaculture":
        return <Aquaculture />;
      case "Insect Farm":
        return <Insect />;
      case "Forestry":
        return <Forestry />;
    }
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {!loading ? (
        <PageWrapFarm header="My Farm Plan" goTo="/account">
          {/* <Control /> */}
          <Tabs
            sx={{ marginBottom: "15px" }}
            value={value}
            onChange={handleChange}
          >
            <Tab sx={{}} label="Farm Map" />
            <Tab label="Farm Plan" />
            <Tab label="Farm Nutrient" />
          </Tabs>
          {value === 0 && <LocationMarker />}
          {value === 1 && <Control />}
          {value === 2 && <FarmNutrient />}
        </PageWrapFarm>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
}

function Dev({ sector }) {
  return (
    <>
      <div className="basic-title">
        We are sorry.
        <p>
          Planning for {sector} is currrently in development, we'll let you know
          when it's ready to go.
        </p>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    data: state.data.getData,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFarmerData: () => dispatch(getFarmerData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FarmPlan);
