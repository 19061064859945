import React from "react";
import { IconButton } from "../../../SubComponents/Button";
import "../UserAccount.css";

export function Food() {
  return (
    <>
      <IconButton
        title="Add and categorize meals"
        icon="food"
        label={"Meal Management"}
        color="cyan"
        goTo="/meal-management"
      />

      <IconButton
        title="Add and manage food suppliers"
        icon="delivery"
        label="Supplier Management"
        color="purple"
        goTo="/supplier-management"
      />
    </>
  );
}

export function Health() {
  return (
    <>
      <IconButton
        icon="report"
        title="View reports on meal consumption patterns"
        label="Consumption Report"
        color="yellow"
        goTo="/consumption-report"
        // disabled="true"
      />

      <IconButton
        title="Offer coupons "
        icon="intervention"
        label="Intervention Management"
        color="green"
        goTo="/intervention"
        // disabled="true"
      />
    </>
  );
}

export function Environment() {
  return (
    <>
      <IconButton
        title="Track food waste"
        icon="waste"
        label="Waste Monitoring"
        color="turquoise"
        goTo="/track-food-waste"
      />
      <IconButton
        title="Monitor the carbon footprint of food supplies"
        icon="report"
        label="Carbon Footprint Reporting"
        color="yellow"
        goTo="/monitor-carbon-footprint"
      />
    </>
  );
}

export function Funds() {
  return (
    <>
      <IconButton
        title="Transfer voucher or deposit funds."
        icon="wallet"
        label="Wallet"
        color="turquoise"
        goTo="/wallet"
      />
      <IconButton
        title="View your transaction history."
        icon="transactions"
        label="Transactions"
        color="yellow"
        goTo="/transactions"
      />
      <IconButton
        title="Create a coupon."
        icon="coupon"
        label="Create Coupon"
        color="purple"
        goTo="/create-coupon"
      />
      <IconButton
        title="Redeem a coupon."
        icon="redeem"
        label="Redeem Coupon"
        color="cyan"
        goTo="/redeem-coupon"
      />
      <IconButton
        title="View your transaction history."
        icon="list"
        label="View My Coupons"
        color="orange"
        goTo="/coupon-transactions"
      />
      <IconButton
        title="Withdraw fund from your wallet."
        icon="list"
        label="Withdraw Fund"
        color="green"
        goTo="/withdraw-funds"
      />
    </>
  );
}
