import React from "react";

function Icon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="3rem"
			height="3rem"
			viewBox="0 0 1920 1920"
			fill="currentColor"
		>
			<path
				fillRule="evenodd"
				d="M1688.412 1807.059H332.059v-326.275h56.515c31.196 0 56.514-25.299 56.514-56.47 0-31.172-25.318-56.47-56.514-56.47h-56.515V1029.02h56.515c31.196 0 56.514-25.3 56.514-56.471 0-31.172-25.318-56.47-56.514-56.47h-56.515V577.254h56.515c31.196 0 56.514-25.299 56.514-56.47 0-31.172-25.318-56.471-56.514-56.471h-56.515V112.942h1356.353v1694.117zM219.029 0v464.314h-56.514c-31.196 0-56.515 25.299-56.515 56.47 0 31.172 25.319 56.47 56.515 56.47h56.514v338.824h-56.514c-31.196 0-56.515 25.3-56.515 56.471 0 31.172 25.319 56.47 56.515 56.47h56.514v338.824h-56.514c-31.196 0-56.515 25.299-56.515 56.47 0 31.172 25.319 56.471 56.515 56.471h56.514V1920h1582.412V0H219.03zM1029 499c112.782 0 226.444 91.718 226.444 204.5v102.25c0 44.99-22.423 87.424-47.167 121.269 40.798 9.611 66.225 21.165 105.285 38.037 75.563 32.72 124.438 106.646 124.438 188.446v140.799l-24.029 15.03c-69.632 43.457-207.772 111.862-386.607 111.862-17.485 0-35.174-.716-53.375-2.045-116.053-8.896-230.164-46.933-330.165-110.021L620 1294.096v-139.98c0-81.902 49.163-152.712 122.495-187.629 40.64-19.35 59.25-30.087 91.408-39.468-25.255-34.05-41.383-75.665-41.383-121.269V703.5C792.52 590.718 916.218 499 1029 499zm-10.58 510.61c-80.062 1.432-159 18.1-235.278 51.126-37.014 15.95-60.839 52.76-60.839 93.763v82.925c79.96 45.603 169.122 73.21 259.51 80.164 156.034 11.759 281.904-39.98 353.99-80.369v-83.333c0-41.003-24.642-78.12-62.679-94.582-63.088-27.198-129.346-43.66-196.831-48.773-13.19-1.022-26.278-.511-39.469-.716-1.329.052-5.036.026-8.857-.025l-.918-.013-.916-.013c-3.192-.05-6.21-.11-7.714-.153zm10.74-408.307c-56.442 0-141.022 45.91-141.022 102.25v102.25c0 55.113 82.842 99.592 137.545 101.535 2.148 0 4.295.102 6.442.102 54.908-1.636 128.086-46.32 128.086-101.637v-102.25c0-56.34-74.61-102.25-131.051-102.25z"
			></path>
		</svg>
	);
}

export default Icon;
